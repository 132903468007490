<template>
  <div id="app" class="app" data-server-rendered="true">
    <Nav />

    <main class="main">
      <VueTransition>
        <router-view :key="$route.path" />
      </VueTransition>
    </main>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';
import VueTransition from '@/components/VueTransition.vue';

export default {
  name: 'App',
  components: {
    Nav,
    VueTransition,
  },
};
</script>

<style lang="scss">
@import './scss/_reset.scss';
@import './scss/_utils.scss';
@import './scss/_fonts.scss';
@import './scss/_animations.scss';

:root {
  --main-color: #2c0bfd;
  --accent-color: #fee3f6;
  --error-color: #fd0b0b;
  --white: #ffffff;
  --pink: #ff00ae;

  font-size: 2.7vmin;
}

@include breakpoint(laptop-sm) {
  :root {
    font-size: 0.5vw;
  }
}

@include breakpoint(desktop-lg) {
  :root {
    font-size: 0.5vw;
  }
}

html,
body {
  overflow: hidden;
  height: 100%;
}

body {
  font-family: 'Roboto Mono', monospace;
}
</style>

<style lang="scss" scoped>
.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  overflow: auto;

  -webkit-overflow-scrolling: touch;

  @include breakpoint(laptop-sm) {
    border: 0.5rem solid var(--main-color);
  }
}

.main {
  position: relative;

  overflow: hidden;

  width: 100%;
  height: 100%;
}
</style>
