import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import { gtm } from './gtm.js';

Vue.config.productionTip = false;

// Use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // Use the language from the routing meta or default language
  let language = to.meta.lang;

  if (!language) {
    i18n.locale = 'ru';
  } else if (i18n.locale !== language) {
    i18n.locale = language;
  }

  // Set current language to <html>
  document.documentElement.lang = language;

  next();
});

new Vue({
  router,
  i18n,
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'));

    // Call once
    if (window.__PRERENDER_INJECTED === undefined) {
      gtm();
    }
  },
  render: h => h(App),
}).$mount('#app');
