<template>
  <section class="thank">
    <button
      class="thank__close"
      :aria-label="$t('modals.closeButton')"
      @click="goBack"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M1.82397 1.95555L11.8659 11.937M11.8659 11.937L21.9078 1.95555M11.8659 11.937L1.82397 21.9185M11.8659 11.937L21.9078 21.9185"
          stroke="#fee3f6"
          stroke-width="4"
        />
      </svg>
    </button>

    <div class="thank__container">
      <h1 class="thank__title">{{ $t('thank.title') }}</h1>
      <p class="thank__text">{{ $t('thank.text-1') }}</p>
      <p class="thank__text">{{ $t('thank.text-2') }}</p>
    </div>
  </section>
</template>

<script>
import { getRootPath } from '../mixins/getRootPath.js';
import { goBack } from '../mixins/goBack';
import { head } from '../mixins/head.js';

export default {
  mixins: [head, getRootPath, goBack],
};
</script>

<style lang="scss" scoped>
.thank {
  position: relative;
  z-index: 3;

  display: flex;
  justify-content: center;

  height: 100%;
  padding-top: 27vh;

  color: var(--accent-color);

  background-color: var(--main-color);

  @include breakpoint(laptop-sm) {
    padding-right: 6rem;
  }
}

.thank > * {
  transition: opacity 650ms ease-in-out;
}

.thank.slide-leave-active > *,
.thank.slide-enter-active > * {
  opacity: 0;

  transition: opacity 1000ms ease-in-out;
}

// Container

.thank__container {
  padding-left: 4rem;
  padding-right: 3.5rem;

  @include breakpoint(laptop-sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

// Close button

.thank__close {
  position: absolute;
  z-index: 2;
  top: 8.1rem;
  right: 7.9rem;

  width: 2rem;
  height: 2rem;

  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @include breakpoint(laptop-sm) {
    width: 2.6rem;
    height: 2.6rem;

    background-size: 2rem;
  }
}

.thank__close svg {
  @extend %centering;

  width: 1rem;
  height: auto;

  stroke: var(--accent-color);

  @include breakpoint(laptop-sm) {
    width: 2rem;
  }
}

.thank__close path {
  transition: stroke 250ms ease;
}

@include mouse {
  .thank__close:hover path,
  .thank__close:focus path {
    stroke: var(--pink);
  }
}

// Title

.thank__title {
  margin-bottom: 3rem;

  font-size: 3.6rem;

  @include breakpoint(laptop-sm) {
    margin-bottom: 6rem;

    font-size: 14.4rem;
  }
}

// Text

.thank__text {
  font-size: 1.8rem;

  @include breakpoint(laptop-sm) {
    font-size: 3.6rem;
  }
}

.thank__text + .thank__text {
  margin-top: 1rem;

  @include breakpoint(laptop-sm) {
    margin-top: 0;
  }
}
</style>
