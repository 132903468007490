import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';

// Views
import Home from './views/Home.vue';
import Thank from './views/Thank.vue';

// Vue.use(VueHead);
Vue.use(Router);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/ro',
      name: 'home_ro',
      component: Home,
      meta: { lang: 'ro' },
    },
    {
      path: '/ro/thank',
      name: 'thank_ro',
      component: Thank,
      meta: { lang: 'ro' },
    },
    {
      path: '/ru',
      name: 'home_ru',
      component: Home,
      alias: '/',
      meta: { lang: 'ru' },
    },
    {
      path: '/ru/thank',
      name: 'thank_ru',
      component: Thank,
      alias: '/thank',
      meta: { lang: 'ru' },
    },

    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home,
    // },
    // {
    //   path: '/thank',
    //   name: 'thank',
    //   component: Thank,
    // },
    // {
    //   path: '/:lang',
    //   component: {
    //     render: h => h('router-view'),
    //   },
    //   children: [
    //     {
    //       path: '',
    //       name: 'home_lang',
    //       component: Home,
    //     },
    //     {
    //       path: 'thank',
    //       name: 'thank_lang',
    //       component: Thank,
    //     },
    //   ],
    // },
    {
      path: '*',
      redirect: '/',
      component: Home,
    },
  ],
});
