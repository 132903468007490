<template>
  <h3 class="announce">{{ $t('modals.announce') }}</h3>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.announce {
  font-size: 1.2rem;

  @include breakpoint(laptop-sm) {
    font-size: 2.4rem;
  }
}
</style>
