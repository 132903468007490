<template>
  <section class="home">
    <!-- Background video -->
    <VideoBackground />

    <!-- Modals -->
    <transition-group :duration="1000" name="modal" class="modals" tag="div">
      <Modal
        v-for="modal in modals"
        :key="modal.type"
        :modal-type="modal.type"
        :shadow-position-x="modal.shadowPosition.x"
        :shadow-position-y="modal.shadowPosition.y"
        :is-clickable="modal.eventHandlers.click"
        :is-hoverable="modal.eventHandlers.hover"
        :data-type="modal.type"
        @liftedUp="liftUpModal($event)"
      >
        <component :is="modal.component" :key="modal.type" />
      </Modal>
    </transition-group>
  </section>
</template>

<script>
// Modals
import Form from '../components/modals/Form.vue';
import Modal from '../components/modals/Modal.vue';
import Timer from '../components/modals/Timer.vue';
import Banner from '../components/modals/Banner.vue';
import Announce from '../components/modals/Announce.vue';

import VideoBackground from '../components/VideoBackground.vue';

// Mixins
import { enableButton } from '../mixins/enableButton.js';
import { reorderArray } from '../mixins/reorderArray.js';
import { getTransitionendHandler } from '../mixins/getTransitionendHandler.js';
import { head } from '../mixins/head.js';

export default {
  name: 'Home',
  components: {
    Form,
    Modal,
    VideoBackground,
    FormModal: Form,
    TimerModal: Timer,
    BannerModal: Banner,
    AnnounceModal: Announce,
  },
  mixins: [head, enableButton, reorderArray, getTransitionendHandler],
  data() {
    return {
      modals: [
        {
          component: 'BannerModal',
          type: 'banner',
          shadowPosition: {
            x: 'left',
            y: 'bottom',
          },
          eventHandlers: {
            click: true,
            hover: false,
          },
        },
        {
          component: 'AnnounceModal',
          type: 'announce',
          shadowPosition: {
            x: 'right',
            y: 'bottom',
          },
          eventHandlers: {
            click: true,
            hover: false,
          },
        },
        {
          component: 'FormModal',
          type: 'form',
          shadowPosition: {
            x: 'right',
            y: 'top',
          },
          eventHandlers: {
            click: true,
            hover: true,
          },
        },
        {
          component: 'TimerModal',
          type: 'timer',
          shadowPosition: {
            x: 'right',
            y: 'bottom',
          },
          eventHandlers: {
            click: true,
            hover: false,
          },
        },
      ],
    };
  },
  methods: {
    liftUpModal(modal) {
      const index = this.modals.findIndex(el => el.type === modal.dataset.type);

      /**
       * Transition prorerty name(s).
       * @type string|string[]
       */
      const propertyNames = ['opacity', 'transform'];

      /**
       * Callback for "transitionend" event handler.
       */
      const transitionCallback = () => {
        // Show modal
        modal.classList.remove('hidden');

        const closeButtons = document.querySelectorAll('.modal .modal__close');

        // Enable all modal's close buttons
        closeButtons.forEach(this.enableButton);
      };

      modal.addEventListener(
        'transitionend',
        this.getTransitionendHandler(transitionCallback, propertyNames)
      );

      this.reorderArray(index, 0, this.modals);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  overflow: auto;

  width: 100%;
  height: 100%;
  padding: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modals {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(laptop-sm) {
    display: block;
    height: 100%;
  }
}

// Modal list transition

.modal-move {
  will-change: transform, opacity;
  transition: transform 600ms 100ms;
}
</style>
