export const enableButton = {
  methods: {
    /**
     * Enable button. Remove "disabled" attribute.
     *
     * @param {HTMLButtonElement} button
     */
    enableButton(button) {
      button.removeAttribute('disabled');
    },
  },
};
