<template>
  <form
    class="form"
    :action="actionURL"
    target="no-target"
    method="POST"
    novalidate
    @submit.prevent="submitHandler"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <h3 class="form__title" v-html="$t('modals.form.title')"></h3>

    <div class="form__inner">
      <div class="form__group">
        <input
          id="name-input"
          type="text"
          name="name"
          class="form__input"
          :placeholder="$t('modals.form.namePlaceholder')"
          :area-label="$t('modals.form.namePlaceholder')"
        />
        <p v-show="!validity.name" class="form__error">
          {{ $t('modals.form.errorMessage') }}
        </p>
      </div>

      <div class="form__group">
        <input
          id="phone-input"
          v-imask="phoneData.mask"
          type="tel"
          name="phone"
          class="form__input"
          :value="phoneData.value"
          :placeholder="$t('modals.form.phonePlaceholder')"
          :area-label="$t('modals.form.phonePlaceholder')"
          @accept="phoneChangeHandler"
        />
        <p v-show="!validity.phone" class="form__error">
          {{ $t('modals.form.errorMessage') }}
        </p>
      </div>

      <div class="form__group">
        <input
          id="email-input"
          type="email"
          name="email"
          class="form__input"
          :placeholder="$t('modals.form.emailPlaceholder')"
          :area-label="$t('modals.form.emailPlaceholder')"
        />
        <p v-show="!validity.email" class="form__error">
          {{ $t('modals.form.errorMessage') }}
        </p>
      </div>

      <button class="form__submit" type="submit">
        {{ $t('modals.form.submitButton') }}
      </button>

      <iframe
        id="no-target"
        src="about:blank"
        name="no-target"
        style="display: none; visibility: hidden;"
      ></iframe>
    </div>
  </form>
</template>

<script>
// eslint-disable vue/no-console
import { IMaskDirective } from 'vue-imask';

export default {
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      actionURL: '',
      validity: {
        name: true,
        email: true,
        phone: true,
      },
      phoneData: {
        value: '',
        isComplete: false,
        mask: {
          mask: '+{373} 000 00 000',
          lazy: true,
        },
      },
    };
  },
  methods: {
    /**
     * Phone change handler.
     *
     * @param {CustomEvent} event
     */
    phoneChangeHandler(event) {
      this.phoneData.value = event.detail.value;
      this.phoneData.isComplete = event.detail.masked.isComplete;
    },

    /**
     * Submit event handler.
     *
     * @param {Event} event
     */
    submitHandler(event) {
      const form = event.target;

      if (!this.validate(form)) return;

      let url =
        'https://docs.google.com/forms/u/1/d/e/1FAIpQLSfad7Kr0s_E7k1mKhzEcpPL3CqiFAnjRAXBF4yEfJ1plRYJNQ/formResponse';

      const formData = new FormData(form);

      const name = form.querySelector('#name-input').value;
      const nameQuery = `entry.395283315=${encodeURIComponent(name)}`;

      const phone = form.querySelector('#phone-input').value;
      const phoneQuery = `entry.681688811=${encodeURIComponent(phone)}`;

      const email = form.querySelector('#email-input').value;
      const emailQuery = `entry.227329498=${encodeURIComponent(email)}`;

      formData.append('lang', this.$i18n.locale);
      const langQuery = `entry.1100608735=${this.$i18n.locale}`;

      const params = new URL(document.location).searchParams;

      const utm = {
        utm_source: 'entry.1623544714',
        utm_medium: 'entry.599158882',
        utm_campaign: 'entry.2019160743',
        utm_content: 'entry.1588298048',
      };

      let utmQuery = '';

      for (var label in utm) {
        if (utm.hasOwnProperty(label)) {
          const name = utm[label];
          const value = params.get(label) || '';

          formData.append(label, value);
          utmQuery += `${name}=${value}&`;
        }
      }

      form.action =
        url +
        '?' +
        nameQuery +
        '&' +
        phoneQuery +
        '&' +
        emailQuery +
        '&' +
        langQuery +
        '&' +
        utmQuery +
        'submit=Submit';

      form.closest('.modal').classList.add('flickering');

      this.sendToTelegram(formData);

      form.submit();

      setTimeout(() => {
        form.reset();
      }, 1000);

      const path = this.$route.path === '/' ? '' : this.$route.path;

      // Redirect to thank page
      this.$router.push(`${path}/thank`);
    },

    /**
     * Validate form.
     *
     * @param {HTMLFormElement} form
     * @returns {boolean} - Validity status.
     */
    validate(form) {
      let isValid = true;

      const name = form.querySelector('#name-input');
      const isValidName = this.validateName(name);
      this.toogleError(name, isValidName);

      const email = form.querySelector('#email-input');
      const isValidEmail = this.validateEmail(email);
      this.toogleError(email, isValidEmail);

      const phone = form.querySelector('#phone-input');
      const isValidPhone = this.validatePhone(phone);
      this.toogleError(phone, isValidPhone);

      if (!isValidPhone || !isValidEmail || !isValidPhone) {
        isValid = false;
      }

      return isValid;
    },

    /**
     * Validate name input.
     *
     * @param {HTMLInputElement} input - Name input.
     * @returns {boolean} - Name validity.
     */
    validateName(input) {
      return input.value.trim() !== '';
    },

    /**
     * Validate email input.
     *
     * @param {HTMLInputElement} input - Email input.
     * @returns {boolean} - Email validity.
     */
    validateEmail(input) {
      // eslint-disable-next-line no-useless-escape
      var regEx = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

      return input.value.trim() !== '' && regEx.test(input.value);
    },

    /**
     * Validate phone input.
     *
     * @param {HTMLInputElement} input - Phone input.
     * @returns {boolean} - Phone validity.
     */
    validatePhone(input) {
      return input.value.trim() !== '' && this.phoneData.isComplete;
    },

    /**
     * Toogle error message visibility.
     *
     * @param {HTMLInputElement} input - Input wich related with error message.
     * @param {boolean} isValid - Input validity.
     */
    toogleError(input, isValid) {
      if (isValid) {
        this.validity[input.name] = true;

        if (input.classList.contains('error')) {
          input.classList.remove('error');
        }
      } else {
        this.validity[input.name] = false;

        input.classList.add('error');
      }
    },

    /**
     * Send form data to telegram chat.
     *
     * @param {FormData} data - Form data wich will be send.
     */
    sendToTelegram(data) {
      try {
        fetch('/server/telegram.php', {
          method: 'POST',
          body: data,
        })
          .then(response => response.json())
          .then(result => console.log('Success:', JSON.stringify(result)))
          .catch(error => {
            console.error('Error:', error);
          });
      } catch (error) {
        console.error('Error:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  font-size: 1.2rem;
}

// Form inner

.form__inner {
  padding-right: 1rem;

  @include breakpoint(laptop-sm) {
    padding-right: 0;
  }
}

// Title

.form__title {
  margin-bottom: 1rem;

  font-size: 1.8rem;
  font-weight: 700;
}

@include breakpoint(laptop-sm) {
  .form__title {
    margin-bottom: 3.2rem;

    font-size: 2.8rem;
  }
}

// Input group

.form__group {
  position: relative;
}

.form__group + .form__group {
  margin-top: 2rem;

  @include breakpoint(laptop-sm) {
    margin-top: 1.8rem;
  }
}

// Input

.form__input {
  display: block;

  width: 100%;
  height: 3rem;

  color: var(--main-color);
  font-size: 1.2rem;

  border: none;
  border-bottom: 2px solid var(--main-color);
  background-color: var(--white);
}

@include breakpoint(laptop-sm) {
  .form__input {
    height: 4rem;

    font-size: 1.8rem;

    border-width: 0.4rem;
  }
}

.form__input:focus {
  outline: none;
}

// Input error

.form__input.error {
  border-bottom-color: var(--error-color);
}

// Error message

.form__error {
  position: absolute;
  top: calc(100% + 2px);
  bottom: -100%;

  // opacity: 0;

  color: var(--error-color);
  font-size: 0.9rem;

  transition: opacity 250ms ease;
}

// Submit button

.form__submit {
  display: block;

  width: 100%;
  height: 4rem;
  margin-top: 3rem;

  color: var(--white);
  font-size: 1.4rem;

  cursor: pointer;
  border: none;
  background-color: var(--main-color);
  transition: background-color 250ms ease;
}

@include breakpoint(laptop-sm) {
  .form__submit {
    height: 6rem;
    margin-top: 6rem;

    font-size: 1.8rem;
  }

  .form__submit:hover,
  .form__submit:focus {
    background-color: var(--pink);
  }
}
</style>
