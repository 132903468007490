<template>
  <nav class="nav">
    <router-link :to="{ path: getRootPath() }">
      <a>
        <img
          src="../assets/logo.svg"
          alt="Academy Digital Skills"
          class="nav__logo"
          width="61"
          height="215"
        />
      </a>
    </router-link>

    <ul class="nav__langs">
      <router-link
        :to="{ path: ruLink }"
        tag="li"
        class="nav__lang"
        active-class="active"
      >
        <a class="nav__link">ru</a>
      </router-link>
      <router-link to="/ro" tag="li" class="nav__lang" active-class="active">
        <a class="nav__link">ro</a>
      </router-link>
    </ul>

    <div class="nav__founders">{{ $t('nav.founder') }}</div>
  </nav>
</template>

<script>
import { getRootPath } from '../mixins/getRootPath';

export default {
  name: 'Nav',
  mixins: [getRootPath],
  computed: {
    ruLink() {
      const paths = ['/', '/thank'];

      return paths.includes(this.$route.path) ? '/' : '/ru';
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;

  padding: 2rem;
  width: 7rem;

  color: var(--main-color);
  font-size: 1.4rem;

  background-color: var(--accent-color);
  border-right: 2px solid var(--main-color);
}

@include breakpoint(laptop-sm) {
  .nav {
    width: 18rem;
    padding: 6rem 3rem;

    font-size: 2.4rem;

    border-width: 0.5rem;
  }
}

// Hide scrollbar

.nav::-webkit-scrollbar {
  display: none;
}

.nav__logo {
  width: 3rem;
  height: auto;

  @include breakpoint(laptop-sm) {
    width: 6.1rem;
  }
}

// Langs container

.nav__langs {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// Language switcher

.nav__lang {
  position: relative;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav__lang + .nav__lang {
  margin-top: 2.8rem;
}

.nav__link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;

  color: inherit;
  text-decoration-color: currentColor;
  transition: color 300ms ease;
}

// Lang link hover|focus

@include mouse {
  .nav__link:hover,
  .nav__link:focus {
    color: var(--pink);
  }
}

:not(.active) > .nav__link {
  text-decoration: none;
}

.nav__founders {
  transform: rotate(-180deg);

  font-weight: 700;
  white-space: nowrap;
  writing-mode: tb-rl;
}

@supports (writing-mode: vertical-rl) {
  .nav__founders {
    transform: rotate(180deg);

    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}
</style>
