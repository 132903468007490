export const goBack = {
  methods: {
    /**
     * Go to previous address in history or root if history is empty.
     */
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push(this.getRootPath());
    },
  },
};
