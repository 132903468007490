<template>
  <div
    class="modal"
    :class="
      `modal--type_${modalType} modal--shadow_${shadowPositionX} modal--shadow_${shadowPositionY}`
    "
  >
    <!-- Close button -->
    <button
      class="modal__close"
      :aria-label="$t('modals.closeButton')"
      v-on="initCloseButtonListeners()"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M1.82397 1.95555L11.8659 11.937M11.8659 11.937L21.9078 1.95555M11.8659 11.937L1.82397 21.9185M11.8659 11.937L21.9078 21.9185"
          stroke="#2C0BFD"
          stroke-width="4"
        />
      </svg>
    </button>

    <div class="modal__inner">
      <slot />
    </div>
  </div>
</template>

<script>
// Mixins
import { isMobile } from '../../mixins/isMobile.js';
import { enableButton } from '../../mixins/enableButton.js';
import { disableButton } from '../../mixins/disableButton.js';
import { getTransitionendHandler } from '../../mixins/getTransitionendHandler.js';

export default {
  mixins: [enableButton, disableButton, isMobile, getTransitionendHandler],
  props: {
    modalType: { type: String, required: true },
    shadowPositionX: { type: String, required: true },
    shadowPositionY: { type: String, required: true },
    isClickable: Boolean,
    isHoverable: Boolean,
  },
  methods: {
    /**
     * Close button click handler.
     *
     * @param {MouseEvent} event
     */
    closeClickHandler(event) {
      const button = event.currentTarget;

      const modal = button.closest('.modal');

      if (modal.dataset.type === 'form') {
        this.moveAway(button);

        return;
      }

      if (this.isMobile()) {
        const closeButtons = document.querySelectorAll('.modal .modal__close');

        // Disable all close buttons
        closeButtons.forEach(this.disableButton);

        // Hide modal
        modal.classList.add('hidden');

        this.$emit('liftedUp', modal);

        return;
      }

      // Disable button
      this.disableButton(button);

      // Hide modal
      modal.classList.add('hidden');

      /**
       * Transition prorerty name(s).
       * @type string|string[]
       */
      const propertyNames = 'opacity';

      /**
       * Callback for "transitionend" event handler.
       */
      const transitionCallback = () => {
        const home = document.querySelector('.home');

        const modalWidth = modal.clientWidth;
        const modalHeight = modal.clientHeight;
        const containerWidth = home.clientWidth;
        const containerHeight = home.clientHeight;

        // Generate transform values
        const x = (Math.random() * (containerWidth - modalWidth)).toFixed();
        const y = (Math.random() * (containerHeight - modalHeight)).toFixed();

        // Set transform property
        modal.style.transform = `translate(${x}px, ${y}px)`;

        // Show modal
        modal.classList.remove('hidden');

        // Enable button
        this.enableButton(button);
      };

      modal.addEventListener(
        'transitionend',
        this.getTransitionendHandler(transitionCallback, propertyNames)
      );

      // Hide modal
      modal.classList.add('hidden');
    },

    /**
     * Form close button hover handler.
     *
     * @param {MouseEvent} event
     */
    hoverHandler({ currentTarget }) {
      this.moveAway(currentTarget);
    },

    /**
     * Buttons run away. New BUTTON coordinates generates randomly.
     *
     * @param {HTMLButtonElement} button - Button element.
     */
    moveAway(button) {
      const modal = button.closest('.modal');

      const modalWidht = modal.clientWidth;
      const modalHeight = modal.clientHeight;
      const buttonSize = button.clientWidth;

      const x = (Math.random() * (modalWidht - buttonSize)).toFixed();
      const y = (Math.random() * (modalHeight - buttonSize)).toFixed();

      button.style.transform = `translate3d(-${x}px, ${y}px, 0)`;
    },

    /**
     * Init close button listeners/
     */
    initCloseButtonListeners() {
      const listeners = {};

      if (this.isClickable) {
        listeners.click = this.closeClickHandler;
      }

      if (this.isHoverable && !this.isMobile()) {
        listeners.mouseenter = this.hoverHandler;
      }

      return listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;

  order: 0;

  color: var(--main-color);
  line-height: 1.15;

  transition: opacity 300ms;
}

@include breakpoint(laptop-sm) {
  .modal {
    position: absolute;

    order: unset;

    border-width: 0.4rem;

    transition: opacity 300ms;
  }
}

// Gap between modals

.modal + .modal {
  margin-top: 1rem;

  @include breakpoint(laptop-sm) {
    margin-top: 0;
  }
}

.modal--shadow_top + .modal--shadow_top,
.modal--shadow_bottom + .modal--shadow_bottom {
  margin-top: 2rem;
}

.modal--shadow_bottom + .modal--shadow_top {
  margin-top: 3rem;
}

.modal--shadow_bottom {
  margin-bottom: 1.5rem;

  @include breakpoint(laptop-sm) {
    margin-bottom: 0;
  }
}

// Banner modal

.modal--type_banner {
  width: 24rem;
  margin-left: 1rem;

  @include breakpoint(laptop-sm) {
    transform: translate(9.3rem, 6.3vh);

    width: 66.5rem;
    margin-left: 0;
  }
}

// Announce modal

.modal--type_announce {
  width: 20rem;
  margin-left: 6rem;

  @include breakpoint(laptop-sm) {
    transform: translate(130rem, 66vh);

    width: 36rem;
    margin-left: 0;
  }
}

// Form modal

.modal--type_form {
  width: 28rem;

  @include breakpoint(laptop-sm) {
    transform: translate(6rem, 42vh);

    width: 48rem;
  }
}

// Timer modal

.modal--type_timer {
  width: 28rem;

  @include breakpoint(laptop-sm) {
    transform: translate(78rem, 42vh);

    width: 48rem;
  }
}

// Modal inner

.modal__inner {
  position: relative;
  z-index: 1;

  width: 100%;
  height: 100%;

  border: 2px solid var(--main-color);
  background-color: var(--white);
}

// Banner modal inner

.modal--type_banner .modal__inner {
  padding: 1.8rem 0.8rem;

  @include breakpoint(laptop-sm) {
    padding: 3rem 9.6rem 3rem 8.2rem;
  }
}

// Announce modal inner

.modal--type_announce .modal__inner {
  padding: 0.8rem 2rem 0.8rem 0.8rem;

  @include breakpoint(laptop-sm) {
    padding: 2.6rem 5.4rem 2.6rem 3.9rem;
  }
}

// Form modal inner

.modal--type_form .modal__inner {
  padding: 1.8rem;

  @include breakpoint(laptop-sm) {
    padding: 3.4rem 4.4rem 5.6rem 5.4rem;
  }
}

// Timer modal inner

.modal--type_timer .modal__inner {
  padding: 1rem 3rem 1rem 1rem;

  @include breakpoint(laptop-sm) {
    padding: 4.8rem 4.6rem 4.6rem 4.3rem;
  }
}

// Close button

.modal__close {
  position: absolute;
  z-index: 2;

  width: 2rem;
  height: 2rem;

  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @include breakpoint(laptop-sm) {
    width: 2.6rem;
    height: 2.6rem;

    background-size: 2rem;
  }
}

.modal__close svg {
  @extend %centering;

  width: 1rem;
  height: auto;

  stroke: var(--main-color);

  @include breakpoint(laptop-sm) {
    width: 2rem;
  }
}

.modal--type_form .modal__close svg {
  filter: drop-shadow(0px 0px 1px var(--white));
}

.modal__close path {
  transition: stroke 250ms ease;
}

// Close button hover

@include mouse {
  .modal__close:hover path,
  .modal__close:focus path {
    stroke: var(--pink);
  }
}

.modal--type_timer,
.modal--type_banner,
.modal--type_announce {
  @include breakpoint(laptop-sm) {
    z-index: 1;
  }
}

.modal--type_timer .modal__close,
.modal--type_banner .modal__close,
.modal--type_announce .modal__close {
  top: 0.5rem;
  right: 0.5rem;

  @include breakpoint(laptop-sm) {
    top: 2.2rem;
    right: 2.2rem;
  }
}

.modal--type_form .modal__close {
  right: 0;
  transform: translate3d(-1.5rem, 1.5rem, 0);

  transition: transform 600ms ease-in-out;

  @include breakpoint(laptop-sm) {
    transform: translate3d(-2.2rem, 2.2rem, 0);
  }
}

// Shadow

.modal::before,
.modal--type_form::after {
  content: '';

  position: absolute;

  width: 100%;
  height: 100%;
}

.modal::before {
  z-index: 1;

  background-color: var(--main-color);
  transition: opacity 250ms ease;
}

.modal--type_form::after {
  background-color: var(--pink);
}

.modal--shadow_left::before,
.modal--shadow_left::after {
  left: -1rem;

  @include breakpoint(laptop-sm) {
    left: -3rem;
  }
}

.modal--shadow_right::before,
.modal--shadow_right::after {
  right: -1rem;

  @include breakpoint(laptop-sm) {
    right: -3rem;
  }
}

.modal--shadow_bottom::before,
.modal--shadow_bottom::after {
  bottom: -1rem;

  @include breakpoint(laptop-sm) {
    bottom: -3rem;
  }
}

.modal--shadow_top::before,
.modal--shadow_top::after {
  top: -1rem;

  @include breakpoint(laptop-sm) {
    top: -3rem;
  }
}
</style>
