export const disableButton = {
  methods: {
    /**
     * Dissable button. Set "disabled" attribute.
     *
     * @param {HTMLButtonElement} button
     */
    disableButton(button) {
      button.setAttribute('disabled', true);
    },
  },
};
