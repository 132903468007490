export const gtm = () => {
  const initGTM = (w, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  };

  initGTM(window, document, 'script', 'dataLayer', 'GTM-W7KCQQ3');

  const iframe = `<iframe
  src="https://www.googletagmanager.com/ns.html?id=GTM-W7KCQQ3"
  height="0"
  width="0"
  style="display: none; visibility: hidden;"
  ></iframe>`;

  document.body.insertAdjacentHTML('afterbegin', iframe);
};
