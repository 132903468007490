<template>
  <h1 class="banner">
    <span class="banner__row">{{ $t('modals.banner.row-1') }}</span>
    <span class="banner__row">{{ $t('modals.banner.row-2') }}</span>
  </h1>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.banner {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;

  @include breakpoint(laptop-sm) {
    font-size: 3.6rem;
  }
}

.banner__row {
  display: block;
}

.banner__row + .banner__row {
  @include breakpoint(laptop-sm) {
    margin-top: 3rem;
  }
}
</style>
