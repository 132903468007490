export const reorderArray = {
  methods: {
    /**
     * Change element position in array.
     *
     * @param {number} from - Current element position.
     * @param {number} to - Final element position.
     * @param {Array} array
     */
    reorderArray(from, to, array) {
      array.splice(to, 0, array.splice(from, 1)[0]);
    },
  },
};
