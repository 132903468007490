<template>
  <div class="timer">
    <!-- Days -->
    <div class="timer__cell">
      <span class="timer__value">{{ days }}</span>
      <span class="timer__unit">{{ $t('modals.timer.days') }}</span>
    </div>

    <!-- Hours -->
    <div class="timer__cell">
      <span class="timer__value">{{ hours }}</span>
      <span class="timer__unit">{{ $t('modals.timer.hours') }}</span>
    </div>

    <!-- Minutes -->
    <div class="timer__cell">
      <span class="timer__value">{{ minutes }}</span>
      <span class="timer__unit">{{ $t('modals.timer.minutes') }}</span>
    </div>

    <!-- Seconds -->
    <div class="timer__cell">
      <span class="timer__value">{{ seconds }}</span>
      <span class="timer__unit">{{ $t('modals.timer.seconds') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timer',
  data() {
    return {
      date: 'December 19, 2020 20:52:50',
      now: Date.now(),
    };
  },
  computed: {
    seconds() {
      const seconds = Math.trunc((this.modifiedDate - this.now) / 1000) % 60;

      return this.prettifyDate(seconds);
    },
    minutes() {
      const minutes =
        Math.trunc((this.modifiedDate - this.now) / 1000 / 60) % 60;

      return this.prettifyDate(minutes);
    },
    hours() {
      const hours =
        Math.trunc((this.modifiedDate - this.now) / 1000 / 60 / 60) % 24;

      return this.prettifyDate(hours);
    },
    days() {
      let days = Math.trunc(
        (this.modifiedDate - this.now) / 1000 / 60 / 60 / 24
      );

      if (days < 0) days = 0;

      return days;
    },
    modifiedDate: function() {
      return Math.trunc(new Date(this.date).getTime());
    },
  },
  mounted() {
    const time = this.timeToDate(this.date);

    if (time > 1000) this.initTimer();
  },
  methods: {
    initTimer() {
      let time = this.timeToDate(this.date);

      const interval = setInterval(() => {
        if (time > 1000) {
          this.now = Date.now();
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    prettifyDate(value) {
      if (value < 0) value = 0;

      if (value < 10) {
        value = '0' + value;
      }

      return value;
    },

    /**
     * @param {string} date
     * @returns {}
     */
    timeToDate(date) {
      return new Date(date).getTime() - Date.now();
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  display: flex;

  text-align: center;
}

// Cell

.timer__cell {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  flex: 1 0 auto;
}

.timer__cell + .timer__cell {
  margin-left: 1rem;

  @include breakpoint(laptop-sm) {
    margin-left: 1.8rem;
  }
}

// Value

.timer__value {
  margin-bottom: 0.8rem;

  font-size: 3rem;
  font-weight: 700;

  @include breakpoint(laptop-sm) {
    font-size: 4.8rem;
  }
}

// Unit

.timer__unit {
  font-size: 1.2em;

  @include breakpoint(laptop-sm) {
    font-size: 1.8rem;
  }
}
</style>
