export const getRootPath = {
  methods: {
    /**
     * Return root path depend on language.
     *
     * @returns {string} Root path.
     */
    getRootPath() {
      const paths = ['/', '/thank'];

      return paths.includes(this.$route.path) ? '/' : `/${this.$i18n.locale}`;
    },
  },
};
