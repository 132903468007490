<template>
  <div class="bg">
    <video
      loop
      muted
      class="video"
      poster="../assets/background/poster-desktop.jpg"
      autoplay
    >
      <source
        v-if="isMobile()"
        src="../assets/background/video-mobile.mp4"
        type="video/mp4"
      />
      <source
        v-else
        src="../assets/background/video-desktop.mp4"
        type="video/mp4"
      />
      This browser does not support the HTML5 video element.
    </video>
  </div>
</template>

<script>
import { isMobile } from '../mixins/isMobile.js';

export default {
  mixins: [isMobile],
};
</script>

<style lang="scss" scoped>
.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: block;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
</style>
