export const head = {
  /**
   * Meta tags in <head>.
   */
  metaInfo() {
    return {
      title: this.$t('head.title'),
      meta: [
        { name: 'description', content: this.$t('head.description') },
        { property: 'og:title', content: this.$t('head.og.title') },
        { property: 'og:url', content: this.$t('domain') + this.$route.path },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: this.$t('head.og.description') },
        { property: 'og:locale', content: this.$t('head.og.locale') },
        {
          property: 'og:image',
          content: `${this.$t('domain')}/share_image_${this.$i18n.locale}.jpg`,
        },
        { property: 'og:width', content: '1200' },
        { property: 'og:height', content: '628' },
        { name: 'twitter:title', content: this.$t('head.title') },
        { name: 'twitter:description', content: this.$t('head.description') },
        {
          name: 'twitter:image',
          content: `${this.$t('domain')}/share_image_${this.$i18n.locale}.jpg`,
        },
        {
          name: 'twitter:domain',
          content: this.$t('domain') + this.$route.path,
        },
        { itemprop: 'name', content: this.$t('head.og.title') },
        { itemprop: 'description', content: this.$t('head.og.description') },
      ],
      // script: [
      //   {
      //     innerHTML: `
      //     (function(w,d,s,l,i){
      //       if (window._didAsyncInjectGoogleAnalytics) return;
      //       w[l]=w[l]||[];w[l].push({'gtm.start':
      //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //     window._didAsyncInjectGoogleAnalytics = true;
      //     })(window,document,'script','dataLayer','GTM-W7KCQQ3');
      // `,
      //   },
      // ],
    };
  },
};
