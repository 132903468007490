export const getTransitionendHandler = {
  methods: {
    /**
     * Transition end handler wrapper. Return "transitionend" handler.
     *
     * @param {Function} callback
     * @param {string|string[]} propertyName - Transition property name(s).
     * @returns {Function} "transitionend" handler.
     */
    getTransitionendHandler(callback, propertyName) {
      /**
       * Transition end handler. Remove event listener after handler call.
       *
       * @param {TransitionEvent} event
       */
      const handler = event => {
        let isMatched = Array.isArray(propertyName)
          ? propertyName.includes(event.propertyName)
          : propertyName === event.propertyName;

        if (isMatched) {
          callback();

          event.currentTarget.removeEventListener('transitionend', handler);
        }
      };

      return handler;
    },
  },
};
