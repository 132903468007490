<template>
  <div class="transition">
    <transition name="slide">
      <slot></slot>
    </transition>

    <div class="transition__overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'VueTransition',
};
</script>

<style lang="scss" scoped>
.transition {
  position: relative;

  height: 100%;
}

.transition__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  z-index: 2;

  visibility: visible;
  transition: transform 800ms ease-in-out 200ms;
  background-color: var(--main-color);
}

.slide-leave-active,
.slide-enter-active {
  transition: opacity 1ms 1000ms;
}

.slide-enter,
.slide-leave-active {
  opacity: 0;
}

.slide-enter ~ .transition__overlay,
.slide-leave-active ~ .transition__overlay {
  transform: translateX(0);
}
</style>
